import API_CONSTANTS from "../../api/api_constants";
import {Link} from "react-router-dom";

const BriefSummary = () => {

    return (
        <div
            className="relative flex flex-col gap-6 text-lm-white bg-lm-blue p-8 overflow-hidden">
            <div className="sm:flex sm:flex-row flex flex-col justify-between gap-8">
                <h2 className="text-2xl font-bold z-10">Brief summary</h2>
                <a href={API_CONSTANTS.base_url + "/AZIZBEK_CV.pdf"} target="_blank" download="AZIZBEK_CV.pdf"
                   className="px-4 py-2 font-semibold w-fit text-lm-white bg-lm-black
                                     active:bg-lm-black hover:bg-opacity-90">
                    Download CV
                </a>
            </div>
            <p className="text-lg z-10">
                I am a full-stack software developer with over 4 years of industry experience, currently working at <Link to="https://www.globalvoices.com/" target="_blank" className="underline">Global Voices ltd.</Link> on innovative
                projects involving modern web technologies and frameworks. Originally from Uzbekistan, I now reside in the UK.
                My expertise includes developing and supporting Application Protocol Interfaces (APIs) using Java and integrating solutions
                with technologies like Next.js and GraphQL. I hold a Bachelor's Degree in Computer Science and a Master's Degree in Artificial Intelligence,
                which has equipped me with a strong foundation in both software development and advanced computing concepts.
            </p>

            <div
                className="absolute rounded-full top-4 left-7 w-16 h-16 bg-lm-pink rounded-full"
            />

            <div
                className="absolute rounded-full lg:-bottom-12 lg:left-6 -right-16 -bottom-16 w-56 h-56 bg-lm-pink rounded-full"
            />
        </div>
    )
};

export default BriefSummary;
